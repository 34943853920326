import { Controller } from "@hotwired/stimulus"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"

export default class extends Controller {
  static targets = [ "text", "icon" ]
  static classes = [ "now", "soonIcon", "nowIcon" ]

  static values = {
    startDate: String,
    imminentDate: String,
    beforeStartText: String,
    imminentText: String,
    afterStartText: String
  }

  initialize() {
    TimeAgo.addDefaultLocale(en)
    this.timeAgo = new TimeAgo()
    this.currentText = null
  }

  connect() {
    this.updateMode()
    this.updateText()
    this.updatePeriodically()
  }

  disconnect() {
    this.stopPeriodicUpdates()
  }

  transitionToNowMode() {
    if (!this.element.classList.contains(this.nowClass)) {
      this.element.classList.add(this.nowClass)
    }

    if (this.iconTarget.classList.contains(this.soonIconClass)) {
      this.iconTarget.classList.remove(this.soonIconClass)
    }

    if (!this.iconTarget.classList.contains(this.nowIconClass)) {
      this.iconTarget.classList.add(this.nowIconClass)
    }
  }

  imminentDateValueChanged() {
    this.imminentDate = new Date(this.imminentDateValue)
  }

  startDateValueChanged() {
    this.eventStartDate = new Date(this.startDateValue)
  }

  checkImminentDatePassed() {
    const currentTime = new Date()
    return (currentTime >= this.imminentDate)
  }

  checkStartDatePassed() {
    const currentTime = new Date()
    return (currentTime >= this.eventStartDate)
  }

  getTemplateText() {
    const imminentDatePassed = this.checkImminentDatePassed()
    const startDatePassed = this.checkStartDatePassed()

    if (imminentDatePassed && !startDatePassed) {
      return this.imminentTextValue
    } else if (startDatePassed) {
      return this.afterStartTextValue
    }

    return this.beforeStartTextValue
  }

  interpolateRelativeTimeInTemplateText(interpolation) {
    const templateText = this.getTemplateText()
    return templateText.replace("%{relativeTime}", interpolation)
  }

  changeText(newText) {
    this.currentText = newText
    this.textTarget.textContent = newText
  }

  updateText() {
    const relativeTime = this.getReformattedDateInRelativeTime()
    const newText = this.interpolateRelativeTimeInTemplateText(relativeTime)
    
    if (this.currentText !== newText) {
      this.changeText(newText)
    }
  }

  getReformattedDateInRelativeTime() {
    const baseString = this.timeAgo.format(this.eventStartDate)
    return baseString.replace("in", "in about")
  }

  updateMode() {
    const imminentDatePassed = this.checkImminentDatePassed()

    if (imminentDatePassed) {
      this.transitionToNowMode()
    }
  }

  refresh() {
    this.updateMode()
    this.updateText()
    this.stopUpdatesIfAppropriate()
  }

  stopUpdatesIfAppropriate() {
    const startDatePassed = this.checkStartDatePassed()

    if (startDatePassed) {
      this.stopPeriodicUpdates()
    }
  }

  updatePeriodically() {
    const refreshRateInMs = 5000

    this.refresher = setInterval(() => {
      this.refresh()
    }, refreshRateInMs)
  }

  stopPeriodicUpdates() {
    if (this.refresher) {
      clearInterval(this.refresher)
    }
  }
}
